/* .buttonLoaders {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem 3.125rem;
  border: none;
  border-radius: 0.3125rem;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.2);
  color: white;
  font-weight: 400;
  text-transform: uppercase;
  overflow: hidden;
}

.buttonLoaders:before {
  content: '';
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  background-color: #02b176;
  z-index: -2;
}

.activeButton:after {
  content: '';
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  background-color: #2ecc71;
  animation: loading 1.2s ease-in-out alternate both infinite;
  animation: loading 1.2s ease-in-out alternate both;
  z-index: -1;
} */

.rotating {
  animation: rotating 500ms linear infinite;
}

@keyframes loading {
  to {
    width: 0%;
  }
}
@keyframes rotating {
  100% {
    transform: rotate(360deg);
  }
}
