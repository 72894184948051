.scrollbar_thinner::-webkit-scrollbar {
  width: 4px !important;
}
.scrollbar_thinner::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.scrollbar_thinner::-webkit-scrollbar-thumb {
  background: #ddd;
}

.menu {
  color: #011b33;
}
.menu_href {
  text-decoration: none;
  color: #011b33;
}
.menu:hover {
  background-color: #02b176 !important;
  color: #fff !important;
}
.menu:focus {
  background-color: #02b17722 !important;
  color: #02b176 !important;
}
.menu:hover .menu_href {
  color: #fff !important;
}

.lg_icon {
  width: 3em !important;
  height: 3em !important;
}

.activeRoomItem {
  background-color: #f0f1f2;
  animation: bgAnim 0.5s linear;
}

@keyframes bgAnim {
  from {
    background-color: #fff;
  }
  to {
    background-color: #02b1760e;
  }
}
