@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,400;0,500;0,600;0,700;0,800;1,500&family=Inter:wght@300;400&family=Source+Sans+Pro:ital,wght@0,400;0,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,700;1,800;1,900&display=swap');

/* @font-face {
  font-family: 'Proxima';
  font-style: normal;
  font-weight: normal;
  src: local('Proxima Medium'), url('./assets/fonts/ProximaMedium.woff') format('woff');
} */

body {
  margin: 0;
  font-family: 'Poppins', 'sans-serif', 'Segoe UI', 'Roboto', 'Oxygen';
  /* font-family: 'Proxima', 'Segoe UI', 'Roboto', 'Oxygen'; */
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
