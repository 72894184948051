@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'icomoon';
  src: url('./assets/fonts/icomoon.eot?luh5yk');
  src: url('./assets/fonts/icomoon.eot?luh5yk#iefix') format('embedded-opentype'),
    url('./assets/fonts/icomoon.ttf?luh5yk') format('truetype'),
    url('./assets/fonts/icomoon.woff?luh5yk') format('woff'),
    url('./assets/fonts/icomoon.svg?luh5yk#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-ayurveda:before {
  content: '\e938';
}
.icon-dentist:before {
  content: '\e939';
}
.icon-dietitian:before {
  content: '\e93a';
}
.icon-gynecologist:before {
  content: '\e93b';
}
.icon-homeopathy:before {
  content: '\e93c';
}
.icon-messageWrite:before {
  content: '\e93d';
}
.icon-physician:before {
  content: '\e93e';
}
.icon-sending:before {
  content: '\e93f';
}
.icon-users:before {
  content: '\e942';
}
.icon-share:before {
  content: '\e940';
}
.icon-smile:before {
  content: '\e941';
}
.icon-account:before {
  content: '\e935';
}
.icon-accountSettings:before {
  content: '\e936';
}
.icon-lock:before {
  content: '\e937';
}
.icon-doctor1:before {
  content: '\e92b';
}
.icon-fastfood:before {
  content: '\e92c';
}
.icon-first-aid-kit:before {
  content: '\e92d';
}
.icon-pharmacy:before {
  content: '\e92e';
}
.icon-heartbeat:before {
  content: '\e92f';
}
.icon-injection:before {
  content: '\e930';
}
.icon-pills:before {
  content: '\e931';
}
.icon-refer:before {
  content: '\e932';
}
.icon-schedule:before {
  content: '\e933';
}
.icon-sendMessage:before {
  content: '\e934';
}
.icon-mail:before {
  content: '\e92a';
}
.icon-notification:before {
  content: '\e91f';
}
.icon-settings:before {
  content: '\e925';
}
.icon-addfile:before {
  content: '\e900';
}
.icon-appointment:before {
  content: '\e901';
}
.icon-attachment:before {
  content: '\e902';
}
.icon-calendar:before {
  content: '\e903';
}
.icon-call:before {
  content: '\e904';
}
.icon-chat:before {
  content: '\e905';
}
.icon-check:before {
  content: '\e906';
}
.icon-clock:before {
  content: '\e907';
}
.icon-close:before {
  content: '\e908';
}
.icon-comment:before {
  content: '\e909';
}
.icon-configuration:before {
  content: '\e90a';
}
.icon-delete:before {
  content: '\e90b';
}
.icon-doctor:before {
  content: '\e90c';
}
.icon-download:before {
  content: '\e90d';
}
.icon-dropArrow:before {
  content: '\e90e';
}
.icon-edit:before {
  content: '\e90f';
}
.icon-editnote:before {
  content: '\e910';
}
.icon-eye:before {
  content: '\e911';
}
.icon-eyeOutline:before {
  content: '\e912';
}
.icon-folder:before {
  content: '\e913';
}
.icon-globe:before {
  content: '\e914';
}
.icon-group:before {
  content: '\e915';
}
.icon-heart-outline:before {
  content: '\e916';
}
.icon-heart:before {
  content: '\e917';
}
.icon-home:before {
  content: '\e918';
}
.icon-list:before {
  content: '\e919';
}
.icon-logout:before {
  content: '\e91a';
}
.icon-members:before {
  content: '\e91b';
}
.icon-message:before {
  content: '\e91c';
}
.icon-meter:before {
  content: '\e91d';
}
.icon-more:before {
  content: '\e91e';
}
.icon-option:before {
  content: '\e920';
}
.icon-patient:before {
  content: '\e921';
}
.icon-plus:before {
  content: '\e922';
}
.icon-report:before {
  content: '\e923';
}
.icon-search:before {
  content: '\e924';
}
.icon-shotOption:before {
  content: '\e926';
}
.icon-target:before {
  content: '\e927';
}
.icon-time:before {
  content: '\e928';
}
.icon-user:before {
  content: '\e929';
}

