.custom-loader {
  width: 100%;
  height: 100%;
  --c: radial-gradient(farthest-side, #4abe82 90%, #0000);
  background: var(--c) 0 0, var(--c) 100% 0, var(--c) 100% 100%, var(--c) 0 100%;
  background-size: 18px 18px;
  background-repeat: no-repeat;
  animation: d8 1s infinite;
}

@keyframes d8 {
  100% {
    background-position: 100% 0, 100% 100%, 0 100%, 0 0;
  }
}
