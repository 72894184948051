@font-face {
  font-family: 'mdoc';
  src: url('../font/mdoc.eot?91692744');
  src: url('../font/mdoc.eot?91692744#iefix') format('embedded-opentype'),
       url('../font/mdoc.woff2?91692744') format('woff2'),
       url('../font/mdoc.woff?91692744') format('woff'),
       url('../font/mdoc.ttf?91692744') format('truetype'),
       url('../font/mdoc.svg?91692744#mdoc') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'mdoc';
    src: url('../font/mdoc.svg?91692744#mdoc') format('svg');
  }
}
*/
[class^="mDoc-"]:before, [class*=" mDoc-"]:before {
  font-family: "mdoc";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.mDoc-mirror:before { content: '\e800'; } /* '' */
.mDoc-milk:before { content: '\e801'; } /* '' */
.mDoc-pet:before { content: '\e802'; } /* '' */
.mDoc-mouse-square:before { content: '\e803'; } /* '' */
.mDoc-reserve:before { content: '\e804'; } /* '' */
.mDoc-safe-home:before { content: '\e805'; } /* '' */
.mDoc-sagittarius:before { content: '\e806'; } /* '' */
.mDoc-search-favorite:before { content: '\e807'; } /* '' */
.mDoc-search-favorite-1:before { content: '\e808'; } /* '' */
.mDoc-search-normal:before { content: '\e809'; } /* '' */
.mDoc-search-normal-1:before { content: '\e80a'; } /* '' */
.mDoc-search-status-1:before { content: '\e80b'; } /* '' */
.mDoc-search-status:before { content: '\e80c'; } /* '' */
.mDoc-search-zoom-in:before { content: '\e80d'; } /* '' */
.mDoc-search-zoom-in-1:before { content: '\e80e'; } /* '' */
.mDoc-search-zoom-out:before { content: '\e80f'; } /* '' */
.mDoc-search-zoom-out-1:before { content: '\e810'; } /* '' */
.mDoc-slider:before { content: '\e811'; } /* '' */
.mDoc-smart-home:before { content: '\e812'; } /* '' */
.mDoc-sound:before { content: '\e813'; } /* '' */
.mDoc-speedometer:before { content: '\e814'; } /* '' */
.mDoc-sticker:before { content: '\e815'; } /* '' */
.mDoc-tag-right:before { content: '\e816'; } /* '' */
.mDoc-story:before { content: '\e817'; } /* '' */
.mDoc-tree:before { content: '\e818'; } /* '' */
.mDoc-triangle-1:before { content: '\e819'; } /* '' */
.mDoc-trush-square:before { content: '\e81a'; } /* '' */
.mDoc-verify:before { content: '\e81b'; } /* '' */
.mDoc-weight-1:before { content: '\e81c'; } /* '' */
.mDoc-wifi:before { content: '\e81d'; } /* '' */
.mDoc-wifi-square:before { content: '\e81e'; } /* '' */
.mDoc-woman:before { content: '\e81f'; } /* '' */
.mDoc-activity:before { content: '\e820'; } /* '' */
.mDoc-add:before { content: '\e821'; } /* '' */
.mDoc-add-circle:before { content: '\e822'; } /* '' */
.mDoc-add-square:before { content: '\e823'; } /* '' */
.mDoc-airplane:before { content: '\e824'; } /* '' */
.mDoc-airplane-square:before { content: '\e825'; } /* '' */
.mDoc-archive-2:before { content: '\e826'; } /* '' */
.mDoc-battery-charging:before { content: '\e827'; } /* '' */
.mDoc-battery-disable:before { content: '\e828'; } /* '' */
.mDoc-battery-empty:before { content: '\e829'; } /* '' */
.mDoc-battery-full:before { content: '\e82a'; } /* '' */
.mDoc-broom:before { content: '\e82b'; } /* '' */
.mDoc-bubble:before { content: '\e82c'; } /* '' */
.mDoc-bus:before { content: '\e82d'; } /* '' */
.mDoc-cake:before { content: '\e82e'; } /* '' */
.mDoc-call:before { content: '\e82f'; } /* '' */
.mDoc-call-add:before { content: '\e830'; } /* '' */
.mDoc-call-calling:before { content: '\e831'; } /* '' */
.mDoc-call-incoming:before { content: '\e832'; } /* '' */
.mDoc-call-minus:before { content: '\e833'; } /* '' */
.mDoc-call-outgoing:before { content: '\e834'; } /* '' */
.mDoc-call-received:before { content: '\e835'; } /* '' */
.mDoc-call-remove:before { content: '\e836'; } /* '' */
.mDoc-call-slash:before { content: '\e837'; } /* '' */
.mDoc-car:before { content: '\e838'; } /* '' */
.mDoc-cd:before { content: '\e839'; } /* '' */
.mDoc-chart:before { content: '\e83a'; } /* '' */
.mDoc-chart-1:before { content: '\e83b'; } /* '' */
.mDoc-chart-2:before { content: '\e83c'; } /* '' */
.mDoc-chart-21:before { content: '\e83d'; } /* '' */
.mDoc-chart-fail:before { content: '\e83e'; } /* '' */
.mDoc-chart-success:before { content: '\e83f'; } /* '' */
.mDoc-close-circle:before { content: '\e840'; } /* '' */
.mDoc-cloud-add:before { content: '\e841'; } /* '' */
.mDoc-cloud-connection:before { content: '\e842'; } /* '' */
.mDoc-cpu:before { content: '\e843'; } /* '' */
.mDoc-crown:before { content: '\e844'; } /* '' */
.mDoc-crown-1:before { content: '\e845'; } /* '' */
.mDoc-cup:before { content: '\e846'; } /* '' */
.mDoc-danger:before { content: '\e847'; } /* '' */
.mDoc-devices-1:before { content: '\e848'; } /* '' */
.mDoc-diagram:before { content: '\e849'; } /* '' */
.mDoc-diamonds:before { content: '\e84a'; } /* '' */
.mDoc-driver-refresh:before { content: '\e84b'; } /* '' */
.mDoc-driving:before { content: '\e84c'; } /* '' */
.mDoc-emoji-happy:before { content: '\e84d'; } /* '' */
.mDoc-emoji-normal:before { content: '\e84e'; } /* '' */
.mDoc-emoji-sad:before { content: '\e84f'; } /* '' */
.mDoc-favorite-chart:before { content: '\e850'; } /* '' */
.mDoc-filter-add:before { content: '\e851'; } /* '' */
.mDoc-filter-edit:before { content: '\e852'; } /* '' */
.mDoc-filter-remove:before { content: '\e853'; } /* '' */
.mDoc-filter-search:before { content: '\e854'; } /* '' */
.mDoc-flag:before { content: '\e855'; } /* '' */
.mDoc-flash-1:before { content: '\e856'; } /* '' */
.mDoc-flash-circle-1:before { content: '\e857'; } /* '' */
.mDoc-flash-slash:before { content: '\e858'; } /* '' */
.mDoc-forbidden:before { content: '\e859'; } /* '' */
.mDoc-forbidden-2:before { content: '\e85a'; } /* '' */
.mDoc-gas-station:before { content: '\e85b'; } /* '' */
.mDoc-ghost:before { content: '\e85c'; } /* '' */
.mDoc-grammerly:before { content: '\e85d'; } /* '' */
.mDoc-graph:before { content: '\e85e'; } /* '' */
.mDoc-hashtag-1:before { content: '\e85f'; } /* '' */
.mDoc-home:before { content: '\e860'; } /* '' */
.mDoc-health:before { content: '\e861'; } /* '' */
.mDoc-home-2:before { content: '\e862'; } /* '' */
.mDoc-home-hashtag:before { content: '\e863'; } /* '' */
.mDoc-home-trend-down:before { content: '\e864'; } /* '' */
.mDoc-home-trend-up:before { content: '\e865'; } /* '' */
.mDoc-info-circle:before { content: '\e866'; } /* '' */
.mDoc-menu-1:before { content: '\e867'; } /* '' */
.mDoc-minus:before { content: '\e868'; } /* '' */
.mDoc-minus-cirlce:before { content: '\e869'; } /* '' */
.mDoc-minus-square:before { content: '\e86a'; } /* '' */
.mDoc-monitor-mobbile:before { content: '\e86b'; } /* '' */
.mDoc-more-circle:before { content: '\e86c'; } /* '' */
.mDoc-more-square:before { content: '\e86d'; } /* '' */
.mDoc-mouse-1:before { content: '\e86e'; } /* '' */
.mDoc-mouse-circle:before { content: '\e86f'; } /* '' */
.mDoc-personalcard:before { content: '\e870'; } /* '' */
.mDoc-presention-chart:before { content: '\e871'; } /* '' */
.mDoc-printer-slash:before { content: '\e872'; } /* '' */
.mDoc-ranking-1:before { content: '\e873'; } /* '' */
.mDoc-send-1:before { content: '\e874'; } /* '' */
.mDoc-send-2:before { content: '\e875'; } /* '' */
.mDoc-share:before { content: '\e876'; } /* '' */
.mDoc-ship:before { content: '\e877'; } /* '' */
.mDoc-signpost:before { content: '\e878'; } /* '' */
.mDoc-slash:before { content: '\e879'; } /* '' */
.mDoc-smart-car:before { content: '\e87a'; } /* '' */
.mDoc-sort:before { content: '\e87b'; } /* '' */
.mDoc-status:before { content: '\e87c'; } /* '' */
.mDoc-status-up:before { content: '\e87d'; } /* '' */
.mDoc-tag-cross:before { content: '\e87e'; } /* '' */
.mDoc-tick-circle:before { content: '\e87f'; } /* '' */
.mDoc-tick-square:before { content: '\e880'; } /* '' */
.mDoc-trash:before { content: '\e881'; } /* '' */
.mDoc-trend-down:before { content: '\e882'; } /* '' */
.mDoc-trend-up:before { content: '\e883'; } /* '' */
.mDoc-warning-2:before { content: '\e884'; } /* '' */
.mDoc-24-support:before { content: '\e885'; } /* '' */
.mDoc-airdrop:before { content: '\e886'; } /* '' */
.mDoc-airpod:before { content: '\e887'; } /* '' */
.mDoc-airpods:before { content: '\e888'; } /* '' */
.mDoc-archive-1:before { content: '\e889'; } /* '' */
.mDoc-archive-add:before { content: '\e88a'; } /* '' */
.mDoc-archive-minus:before { content: '\e88b'; } /* '' */
.mDoc-archive-slash:before { content: '\e88c'; } /* '' */
.mDoc-archive-tick:before { content: '\e88d'; } /* '' */
.mDoc-bluetooth:before { content: '\e88e'; } /* '' */
.mDoc-bluetooth-2:before { content: '\e88f'; } /* '' */
.mDoc-bluetooth-circle:before { content: '\e890'; } /* '' */
.mDoc-bluetooth-rectangle:before { content: '\e891'; } /* '' */
.mDoc-book-saved:before { content: '\e892'; } /* '' */
.mDoc-book-square:before { content: '\e893'; } /* '' */
.mDoc-calendar:before { content: '\e894'; } /* '' */
.mDoc-calendar-1:before { content: '\e895'; } /* '' */
.mDoc-calendar-2:before { content: '\e896'; } /* '' */
.mDoc-calendar-add:before { content: '\e897'; } /* '' */
.mDoc-calendar-circle:before { content: '\e898'; } /* '' */
.mDoc-calendar-edit:before { content: '\e899'; } /* '' */
.mDoc-calendar-remove:before { content: '\e89a'; } /* '' */
.mDoc-calendar-search:before { content: '\e89b'; } /* '' */
.mDoc-calendar-tick:before { content: '\e89c'; } /* '' */
.mDoc-category:before { content: '\e89d'; } /* '' */
.mDoc-clock:before { content: '\e89e'; } /* '' */
.mDoc-clock-1:before { content: '\e89f'; } /* '' */
.mDoc-cloud-change:before { content: '\e8a0'; } /* '' */
.mDoc-cloud-remove:before { content: '\e8a1'; } /* '' */
.mDoc-cpu-charge:before { content: '\e8a2'; } /* '' */
.mDoc-cpu-setting:before { content: '\e8a3'; } /* '' */
.mDoc-dislike:before { content: '\e8a4'; } /* '' */
.mDoc-driver:before { content: '\e8a5'; } /* '' */
.mDoc-driver-2:before { content: '\e8a6'; } /* '' */
.mDoc-electricity:before { content: '\e8a7'; } /* '' */
.mDoc-external-drive:before { content: '\e8a8'; } /* '' */
.mDoc-folder-connection:before { content: '\e8a9'; } /* '' */
.mDoc-game:before { content: '\e8aa'; } /* '' */
.mDoc-gameboy:before { content: '\e8ab'; } /* '' */
.mDoc-headphone:before { content: '\e8ac'; } /* '' */
.mDoc-headphones:before { content: '\e8ad'; } /* '' */
.mDoc-heart:before { content: '\e8ae'; } /* '' */
.mDoc-heart-add:before { content: '\e8af'; } /* '' */
.mDoc-heart-circle:before { content: '\e8b0'; } /* '' */
.mDoc-heart-edit:before { content: '\e8b1'; } /* '' */
.mDoc-heart-remove:before { content: '\e8b2'; } /* '' */
.mDoc-heart-search:before { content: '\e8b3'; } /* '' */
.mDoc-heart-slash:before { content: '\e8b4'; } /* '' */
.mDoc-heart-tick:before { content: '\e8b5'; } /* '' */
.mDoc-keyboard:before { content: '\e8b6'; } /* '' */
.mDoc-keyboard-open:before { content: '\e8b7'; } /* '' */
.mDoc-lamp:before { content: '\e8b8'; } /* '' */
.mDoc-like:before { content: '\e8b9'; } /* '' */
.mDoc-like-1:before { content: '\e8ba'; } /* '' */
.mDoc-like-dislike:before { content: '\e8bb'; } /* '' */
.mDoc-like-shapes:before { content: '\e8bc'; } /* '' */
.mDoc-like-tag:before { content: '\e8bd'; } /* '' */
.mDoc-lovely:before { content: '\e8be'; } /* '' */
.mDoc-magic-star:before { content: '\e8bf'; } /* '' */
.mDoc-medal:before { content: '\e8c0'; } /* '' */
.mDoc-medal-star:before { content: '\e8c1'; } /* '' */
.mDoc-message-question:before { content: '\e8c2'; } /* '' */
.mDoc-microscope:before { content: '\e8c3'; } /* '' */
.mDoc-mirroring-screen:before { content: '\e8c4'; } /* '' */
.mDoc-mobile:before { content: '\e8c5'; } /* '' */
.mDoc-monitor:before { content: '\e8c6'; } /* '' */
.mDoc-monitor-recorder:before { content: '\e8c7'; } /* '' */
.mDoc-more:before { content: '\e8c8'; } /* '' */
.mDoc-more-2:before { content: '\e8c9'; } /* '' */
.mDoc-mouse:before { content: '\e8ca'; } /* '' */
.mDoc-music-play:before { content: '\e8cb'; } /* '' */
.mDoc-printer:before { content: '\e8cc'; } /* '' */
.mDoc-ram:before { content: '\e8cd'; } /* '' */
.mDoc-ram-2:before { content: '\e8ce'; } /* '' */
.mDoc-ranking:before { content: '\e8cf'; } /* '' */
.mDoc-receipt-square:before { content: '\e8d0'; } /* '' */
.mDoc-save-2:before { content: '\e8d1'; } /* '' */
.mDoc-save-add:before { content: '\e8d2'; } /* '' */
.mDoc-save-minus:before { content: '\e8d3'; } /* '' */
.mDoc-save-remove:before { content: '\e8d4'; } /* '' */
.mDoc-security-time:before { content: '\e8d5'; } /* '' */
.mDoc-simcard:before { content: '\e8d6'; } /* '' */
.mDoc-simcard-1:before { content: '\e8d7'; } /* '' */
.mDoc-simcard-2:before { content: '\e8d8'; } /* '' */
.mDoc-smileys:before { content: '\e8d9'; } /* '' */
.mDoc-speaker:before { content: '\e8da'; } /* '' */
.mDoc-star:before { content: '\e8db'; } /* '' */
.mDoc-star-1:before { content: '\e8dc'; } /* '' */
.mDoc-star-slash:before { content: '\e8dd'; } /* '' */
.mDoc-timer:before { content: '\e8de'; } /* '' */
.mDoc-timer-1:before { content: '\e8df'; } /* '' */
.mDoc-timer-pause:before { content: '\e8e0'; } /* '' */
.mDoc-timer-start:before { content: '\e8e1'; } /* '' */
.mDoc-unlimited:before { content: '\e8e2'; } /* '' */
.mDoc-watch:before { content: '\e8e3'; } /* '' */
.mDoc-watch-status:before { content: '\e8e4'; } /* '' */
.mDoc-weight:before { content: '\e8e5'; } /* '' */
.mDoc-alarm:before { content: '\e8e6'; } /* '' */
.mDoc-archive-book:before { content: '\e8e7'; } /* '' */
.mDoc-bill:before { content: '\e8e8'; } /* '' */
.mDoc-candle:before { content: '\e8e9'; } /* '' */
.mDoc-candle-2:before { content: '\e8ea'; } /* '' */
.mDoc-category-2:before { content: '\e8eb'; } /* '' */
.mDoc-check:before { content: '\e8ec'; } /* '' */
.mDoc-clipboard-close:before { content: '\e8ed'; } /* '' */
.mDoc-clipboard-export:before { content: '\e8ee'; } /* '' */
.mDoc-clipboard-import:before { content: '\e8ef'; } /* '' */
.mDoc-clipboard-text:before { content: '\e8f0'; } /* '' */
.mDoc-clipboard-tick:before { content: '\e8f1'; } /* '' */
.mDoc-code:before { content: '\e8f2'; } /* '' */
.mDoc-code-1:before { content: '\e8f3'; } /* '' */
.mDoc-code-circle:before { content: '\e8f4'; } /* '' */
.mDoc-command:before { content: '\e8f5'; } /* '' */
.mDoc-command-square:before { content: '\e8f6'; } /* '' */
.mDoc-copyright:before { content: '\e8f7'; } /* '' */
.mDoc-creative-commons:before { content: '\e8f8'; } /* '' */
.mDoc-data:before { content: '\e8f9'; } /* '' */
.mDoc-data-2:before { content: '\e8fa'; } /* '' */
.mDoc-document-1:before { content: '\e8fb'; } /* '' */
.mDoc-document-cloud:before { content: '\e8fc'; } /* '' */
.mDoc-document-code:before { content: '\e8fd'; } /* '' */
.mDoc-document-code-2:before { content: '\e8fe'; } /* '' */
.mDoc-document-copy:before { content: '\e8ff'; } /* '' */
.mDoc-document-download:before { content: '\e900'; } /* '' */
.mDoc-document-favorite:before { content: '\e901'; } /* '' */
.mDoc-document-filter:before { content: '\e902'; } /* '' */
.mDoc-document-forward:before { content: '\e903'; } /* '' */
.mDoc-document-like:before { content: '\e904'; } /* '' */
.mDoc-document-normal:before { content: '\e905'; } /* '' */
.mDoc-document-previous:before { content: '\e906'; } /* '' */
.mDoc-document-sketch:before { content: '\e907'; } /* '' */
.mDoc-document-text:before { content: '\e908'; } /* '' */
.mDoc-document-text-1:before { content: '\e909'; } /* '' */
.mDoc-document-upload:before { content: '\e90a'; } /* '' */
.mDoc-edit:before { content: '\e90b'; } /* '' */
.mDoc-edit-2:before { content: '\e90c'; } /* '' */
.mDoc-finger-cricle:before { content: '\e90d'; } /* '' */
.mDoc-finger-scan:before { content: '\e90e'; } /* '' */
.mDoc-hashtag:before { content: '\e90f'; } /* '' */
.mDoc-hashtag-down:before { content: '\e910'; } /* '' */
.mDoc-hashtag-up:before { content: '\e911'; } /* '' */
.mDoc-hierarchy:before { content: '\e912'; } /* '' */
.mDoc-hierarchy-2:before { content: '\e913'; } /* '' */
.mDoc-hierarchy-3:before { content: '\e914'; } /* '' */
.mDoc-hierarchy-square:before { content: '\e915'; } /* '' */
.mDoc-hierarchy-square-2:before { content: '\e916'; } /* '' */
.mDoc-hierarchy-square-3:before { content: '\e917'; } /* '' */
.mDoc-key-square:before { content: '\e918'; } /* '' */
.mDoc-lamp-charge:before { content: '\e919'; } /* '' */
.mDoc-lamp-on:before { content: '\e91a'; } /* '' */
.mDoc-lamp-slash:before { content: '\e91b'; } /* '' */
.mDoc-lock-1:before { content: '\e91c'; } /* '' */
.mDoc-lock-circle:before { content: '\e91d'; } /* '' */
.mDoc-lock-slash:before { content: '\e91e'; } /* '' */
.mDoc-menu:before { content: '\e91f'; } /* '' */
.mDoc-menu-board:before { content: '\e920'; } /* '' */
.mDoc-message-programming:before { content: '\e921'; } /* '' */
.mDoc-mobile-programming:before { content: '\e922'; } /* '' */
.mDoc-note-1:before { content: '\e923'; } /* '' */
.mDoc-note-21:before { content: '\e924'; } /* '' */
.mDoc-note-add:before { content: '\e925'; } /* '' */
.mDoc-note-favorite:before { content: '\e926'; } /* '' */
.mDoc-note-remove:before { content: '\e927'; } /* '' */
.mDoc-note-text:before { content: '\e928'; } /* '' */
.mDoc-notification:before { content: '\e929'; } /* '' */
.mDoc-notification-1:before { content: '\e92a'; } /* '' */
.mDoc-notification-bing:before { content: '\e92b'; } /* '' */
.mDoc-notification-circle:before { content: '\e92c'; } /* '' */
.mDoc-notification-favorite:before { content: '\e92d'; } /* '' */
.mDoc-notification-status:before { content: '\e92e'; } /* '' */
.mDoc-programming-arrow:before { content: '\e92f'; } /* '' */
.mDoc-programming-arrows:before { content: '\e930'; } /* '' */
.mDoc-radar-1:before { content: '\e931'; } /* '' */
.mDoc-scan:before { content: '\e932'; } /* '' */
.mDoc-scan-barcode:before { content: '\e933'; } /* '' */
.mDoc-scanner:before { content: '\e934'; } /* '' */
.mDoc-scanning:before { content: '\e935'; } /* '' */
.mDoc-scroll:before { content: '\e936'; } /* '' */
.mDoc-security-safe:before { content: '\e937'; } /* '' */
.mDoc-security-user:before { content: '\e938'; } /* '' */
.mDoc-setting:before { content: '\e939'; } /* '' */
.mDoc-setting-2:before { content: '\e93a'; } /* '' */
.mDoc-setting-3:before { content: '\e93b'; } /* '' */
.mDoc-setting-4:before { content: '\e93c'; } /* '' */
.mDoc-setting-5:before { content: '\e93d'; } /* '' */
.mDoc-settings:before { content: '\e93e'; } /* '' */
.mDoc-sidebar-bottom:before { content: '\e93f'; } /* '' */
.mDoc-sidebar-left:before { content: '\e940'; } /* '' */
.mDoc-sidebar-right:before { content: '\e941'; } /* '' */
.mDoc-sidebar-top:before { content: '\e942'; } /* '' */
.mDoc-stickynote:before { content: '\e943'; } /* '' */
.mDoc-task:before { content: '\e944'; } /* '' */
.mDoc-task-square:before { content: '\e945'; } /* '' */
.mDoc-toggle-off:before { content: '\e946'; } /* '' */
.mDoc-toggle-off-circle:before { content: '\e947'; } /* '' */
.mDoc-toggle-on:before { content: '\e948'; } /* '' */
.mDoc-toggle-on-circle:before { content: '\e949'; } /* '' */
.mDoc-3d-cube-scan:before { content: '\e94a'; } /* '' */
.mDoc-3d-rotate:before { content: '\e94b'; } /* '' */
.mDoc-3d-square:before { content: '\e94c'; } /* '' */
.mDoc-3square:before { content: '\e94d'; } /* '' */
.mDoc-align-bottom:before { content: '\e94e'; } /* '' */
.mDoc-align-horizontally:before { content: '\e94f'; } /* '' */
.mDoc-align-left:before { content: '\e950'; } /* '' */
.mDoc-align-right:before { content: '\e951'; } /* '' */
.mDoc-align-vertically:before { content: '\e952'; } /* '' */
.mDoc-award:before { content: '\e953'; } /* '' */
.mDoc-bag:before { content: '\e954'; } /* '' */
.mDoc-bag-cross-1:before { content: '\e955'; } /* '' */
.mDoc-bag-tick:before { content: '\e956'; } /* '' */
.mDoc-bag-tick-2:before { content: '\e957'; } /* '' */
.mDoc-bag-timer:before { content: '\e958'; } /* '' */
.mDoc-bitcoin-card:before { content: '\e959'; } /* '' */
.mDoc-bitcoin-convert:before { content: '\e95a'; } /* '' */
.mDoc-bitcoin-refresh:before { content: '\e95b'; } /* '' */
.mDoc-book:before { content: '\e95c'; } /* '' */
.mDoc-book-1:before { content: '\e95d'; } /* '' */
.mDoc-bookmark:before { content: '\e95e'; } /* '' */
.mDoc-bookmark-2:before { content: '\e95f'; } /* '' */
.mDoc-box:before { content: '\e960'; } /* '' */
.mDoc-box-1:before { content: '\e961'; } /* '' */
.mDoc-box-add:before { content: '\e962'; } /* '' */
.mDoc-box-remove:before { content: '\e963'; } /* '' */
.mDoc-box-search:before { content: '\e964'; } /* '' */
.mDoc-box-tick:before { content: '\e965'; } /* '' */
.mDoc-box-time:before { content: '\e966'; } /* '' */
.mDoc-briefcase:before { content: '\e967'; } /* '' */
.mDoc-brifecase-cross:before { content: '\e968'; } /* '' */
.mDoc-brifecase-tick:before { content: '\e969'; } /* '' */
.mDoc-brifecase-timer:before { content: '\e96a'; } /* '' */
.mDoc-buy-crypto:before { content: '\e96b'; } /* '' */
.mDoc-calculator:before { content: '\e96c'; } /* '' */
.mDoc-card-coin:before { content: '\e96d'; } /* '' */
.mDoc-clipboard:before { content: '\e96e'; } /* '' */
.mDoc-convert-3d-cube:before { content: '\e96f'; } /* '' */
.mDoc-convertshape:before { content: '\e970'; } /* '' */
.mDoc-convertshape-2:before { content: '\e971'; } /* '' */
.mDoc-crop:before { content: '\e972'; } /* '' */
.mDoc-element-2:before { content: '\e973'; } /* '' */
.mDoc-element-3:before { content: '\e974'; } /* '' */
.mDoc-element-4:before { content: '\e975'; } /* '' */
.mDoc-element-equal:before { content: '\e976'; } /* '' */
.mDoc-element-plus:before { content: '\e977'; } /* '' */
.mDoc-eye:before { content: '\e978'; } /* '' */
.mDoc-eye-slash:before { content: '\e979'; } /* '' */
.mDoc-fatrows:before { content: '\e97a'; } /* '' */
.mDoc-format-circle:before { content: '\e97b'; } /* '' */
.mDoc-format-square:before { content: '\e97c'; } /* '' */
.mDoc-frame-2:before { content: '\e97d'; } /* '' */
.mDoc-frame-3:before { content: '\e97e'; } /* '' */
.mDoc-frame-4:before { content: '\e97f'; } /* '' */
.mDoc-gift:before { content: '\e980'; } /* '' */
.mDoc-grid-1:before { content: '\e981'; } /* '' */
.mDoc-grid-2:before { content: '\e982'; } /* '' */
.mDoc-grid-3:before { content: '\e983'; } /* '' */
.mDoc-grid-4:before { content: '\e984'; } /* '' */
.mDoc-grid-5:before { content: '\e985'; } /* '' */
.mDoc-grid-6:before { content: '\e986'; } /* '' */
.mDoc-grid-7:before { content: '\e987'; } /* '' */
.mDoc-grid-8:before { content: '\e988'; } /* '' */
.mDoc-grid-9:before { content: '\e989'; } /* '' */
.mDoc-grid-edit:before { content: '\e98a'; } /* '' */
.mDoc-grid-eraser:before { content: '\e98b'; } /* '' */
.mDoc-grid-lock:before { content: '\e98c'; } /* '' */
.mDoc-kanban:before { content: '\e98d'; } /* '' */
.mDoc-key:before { content: '\e98e'; } /* '' */
.mDoc-lock:before { content: '\e98f'; } /* '' */
.mDoc-maximize-1:before { content: '\e990'; } /* '' */
.mDoc-maximize-2:before { content: '\e991'; } /* '' */
.mDoc-maximize-3:before { content: '\e992'; } /* '' */
.mDoc-maximize-4:before { content: '\e993'; } /* '' */
.mDoc-maximize-21:before { content: '\e994'; } /* '' */
.mDoc-note:before { content: '\e995'; } /* '' */
.mDoc-note-2:before { content: '\e996'; } /* '' */
.mDoc-password-check:before { content: '\e997'; } /* '' */
.mDoc-rotate-left-1:before { content: '\e998'; } /* '' */
.mDoc-rotate-right-1:before { content: '\e999'; } /* '' */
.mDoc-row-horizontal:before { content: '\e99a'; } /* '' */
.mDoc-row-vertical:before { content: '\e99b'; } /* '' */
.mDoc-security:before { content: '\e99c'; } /* '' */
.mDoc-shield:before { content: '\e99d'; } /* '' */
.mDoc-shield-cross:before { content: '\e99e'; } /* '' */
.mDoc-shield-search:before { content: '\e99f'; } /* '' */
.mDoc-shield-slash:before { content: '\e9a0'; } /* '' */
.mDoc-shield-tick:before { content: '\e9a1'; } /* '' */
.mDoc-slider-horizontal:before { content: '\e9a2'; } /* '' */
.mDoc-slider-horizontal-1:before { content: '\e9a3'; } /* '' */
.mDoc-slider-vertical:before { content: '\e9a4'; } /* '' */
.mDoc-slider-vertical-1:before { content: '\e9a5'; } /* '' */
.mDoc-teacher:before { content: '\e9a6'; } /* '' */
.mDoc-trade:before { content: '\e9a7'; } /* '' */
.mDoc-truck:before { content: '\e9a8'; } /* '' */
.mDoc-truck-fast:before { content: '\e9a9'; } /* '' */
.mDoc-truck-remove:before { content: '\e9aa'; } /* '' */
.mDoc-truck-tick:before { content: '\e9ab'; } /* '' */
.mDoc-truck-time:before { content: '\e9ac'; } /* '' */
.mDoc-unlock:before { content: '\e9ad'; } /* '' */
.mDoc-additem:before { content: '\e9ae'; } /* '' */
.mDoc-arrow:before { content: '\e9af'; } /* '' */
.mDoc-arrow-square:before { content: '\e9b0'; } /* '' */
.mDoc-backward-item:before { content: '\e9b1'; } /* '' */
.mDoc-bag-2:before { content: '\e9b2'; } /* '' */
.mDoc-bag-cross:before { content: '\e9b3'; } /* '' */
.mDoc-bag-happy:before { content: '\e9b4'; } /* '' */
.mDoc-barcode:before { content: '\e9b5'; } /* '' */
.mDoc-bezier:before { content: '\e9b6'; } /* '' */
.mDoc-blend:before { content: '\e9b7'; } /* '' */
.mDoc-blend-2:before { content: '\e9b8'; } /* '' */
.mDoc-blur:before { content: '\e9b9'; } /* '' */
.mDoc-brush:before { content: '\e9ba'; } /* '' */
.mDoc-brush-1:before { content: '\e9bb'; } /* '' */
.mDoc-brush-2:before { content: '\e9bc'; } /* '' */
.mDoc-brush-3:before { content: '\e9bd'; } /* '' */
.mDoc-brush-4:before { content: '\e9be'; } /* '' */
.mDoc-bucket:before { content: '\e9bf'; } /* '' */
.mDoc-bucket-circle:before { content: '\e9c0'; } /* '' */
.mDoc-bucket-square:before { content: '\e9c1'; } /* '' */
.mDoc-colorfilter:before { content: '\e9c2'; } /* '' */
.mDoc-colors-square:before { content: '\e9c3'; } /* '' */
.mDoc-color-swatch:before { content: '\e9c4'; } /* '' */
.mDoc-component:before { content: '\e9c5'; } /* '' */
.mDoc-copy:before { content: '\e9c6'; } /* '' */
.mDoc-copy-success:before { content: '\e9c7'; } /* '' */
.mDoc-designtools:before { content: '\e9c8'; } /* '' */
.mDoc-direct:before { content: '\e9c9'; } /* '' */
.mDoc-directbox-default:before { content: '\e9ca'; } /* '' */
.mDoc-directbox-notif:before { content: '\e9cb'; } /* '' */
.mDoc-directbox-receive:before { content: '\e9cc'; } /* '' */
.mDoc-directbox-send:before { content: '\e9cd'; } /* '' */
.mDoc-direct-down:before { content: '\e9ce'; } /* '' */
.mDoc-direct-inbox:before { content: '\e9cf'; } /* '' */
.mDoc-direct-left:before { content: '\e9d0'; } /* '' */
.mDoc-direct-normal:before { content: '\e9d1'; } /* '' */
.mDoc-direct-notification:before { content: '\e9d2'; } /* '' */
.mDoc-direct-right:before { content: '\e9d3'; } /* '' */
.mDoc-direct-up:before { content: '\e9d4'; } /* '' */
.mDoc-discover:before { content: '\e9d5'; } /* '' */
.mDoc-eraser-1:before { content: '\e9d6'; } /* '' */
.mDoc-flash-circle:before { content: '\e9d7'; } /* '' */
.mDoc-forward-item:before { content: '\e9d8'; } /* '' */
.mDoc-glass:before { content: '\e9d9'; } /* '' */
.mDoc-global:before { content: '\e9da'; } /* '' */
.mDoc-global-edit:before { content: '\e9db'; } /* '' */
.mDoc-global-refresh:before { content: '\e9dc'; } /* '' */
.mDoc-global-search:before { content: '\e9dd'; } /* '' */
.mDoc-gps:before { content: '\e9de'; } /* '' */
.mDoc-gps-slash:before { content: '\e9df'; } /* '' */
.mDoc-layer:before { content: '\e9e0'; } /* '' */
.mDoc-lifebuoy-1:before { content: '\e9e1'; } /* '' */
.mDoc-location:before { content: '\e9e2'; } /* '' */
.mDoc-location-add:before { content: '\e9e3'; } /* '' */
.mDoc-location-cross:before { content: '\e9e4'; } /* '' */
.mDoc-location-minus:before { content: '\e9e5'; } /* '' */
.mDoc-location-slash:before { content: '\e9e6'; } /* '' */
.mDoc-location-tick:before { content: '\e9e7'; } /* '' */
.mDoc-magicpen:before { content: '\e9e8'; } /* '' */
.mDoc-main-component:before { content: '\e9e9'; } /* '' */
.mDoc-map:before { content: '\e9ea'; } /* '' */
.mDoc-map-1:before { content: '\e9eb'; } /* '' */
.mDoc-mask:before { content: '\e9ec'; } /* '' */
.mDoc-mask-1:before { content: '\e9ed'; } /* '' */
.mDoc-mask-2:before { content: '\e9ee'; } /* '' */
.mDoc-message-minus:before { content: '\e9ef'; } /* '' */
.mDoc-messages-2:before { content: '\e9f0'; } /* '' */
.mDoc-omega-circle:before { content: '\e9f1'; } /* '' */
.mDoc-omega-square:before { content: '\e9f2'; } /* '' */
.mDoc-paintbucket:before { content: '\e9f3'; } /* '' */
.mDoc-path:before { content: '\e9f4'; } /* '' */
.mDoc-path-2:before { content: '\e9f5'; } /* '' */
.mDoc-path-square:before { content: '\e9f6'; } /* '' */
.mDoc-pen-add:before { content: '\e9f7'; } /* '' */
.mDoc-pen-close:before { content: '\e9f8'; } /* '' */
.mDoc-pen-remove:before { content: '\e9f9'; } /* '' */
.mDoc-pen-tool:before { content: '\e9fa'; } /* '' */
.mDoc-pen-tool-2:before { content: '\e9fb'; } /* '' */
.mDoc-picture-frame:before { content: '\e9fc'; } /* '' */
.mDoc-radar:before { content: '\e9fd'; } /* '' */
.mDoc-radar-2:before { content: '\e9fe'; } /* '' */
.mDoc-recovery-convert:before { content: '\e9ff'; } /* '' */
.mDoc-route-square:before { content: '\ea00'; } /* '' */
.mDoc-routing:before { content: '\ea01'; } /* '' */
.mDoc-routing-2:before { content: '\ea02'; } /* '' */
.mDoc-ruler&pen:before { content: '\ea03'; } /* '' */
.mDoc-ruler:before { content: '\ea04'; } /* '' */
.mDoc-scissor-1:before { content: '\ea05'; } /* '' */
.mDoc-shapes:before { content: '\ea06'; } /* '' */
.mDoc-shapes-1:before { content: '\ea07'; } /* '' */
.mDoc-shop:before { content: '\ea08'; } /* '' */
.mDoc-shop-add:before { content: '\ea09'; } /* '' */
.mDoc-shopping-bag:before { content: '\ea0a'; } /* '' */
.mDoc-shopping-cart:before { content: '\ea0b'; } /* '' */
.mDoc-shop-remove:before { content: '\ea0c'; } /* '' */
.mDoc-size:before { content: '\ea0d'; } /* '' */
.mDoc-sms-tracking:before { content: '\ea0e'; } /* '' */
.mDoc-android:before { content: '\ea0f'; } /* '' */
.mDoc-be:before { content: '\ea10'; } /* '' */
.mDoc-blogger:before { content: '\ea11'; } /* '' */
.mDoc-cloud:before { content: '\ea12'; } /* '' */
.mDoc-cloud-cross:before { content: '\ea13'; } /* '' */
.mDoc-cloud-drizzle:before { content: '\ea14'; } /* '' */
.mDoc-cloud-fog:before { content: '\ea15'; } /* '' */
.mDoc-cloud-lightning:before { content: '\ea16'; } /* '' */
.mDoc-cloud-minus:before { content: '\ea17'; } /* '' */
.mDoc-cloud-notif:before { content: '\ea18'; } /* '' */
.mDoc-cloud-plus:before { content: '\ea19'; } /* '' */
.mDoc-cloud-snow:before { content: '\ea1a'; } /* '' */
.mDoc-cloud-sunny:before { content: '\ea1b'; } /* '' */
.mDoc-device-message:before { content: '\ea1c'; } /* '' */
.mDoc-direct-send:before { content: '\ea1d'; } /* '' */
.mDoc-dribbble:before { content: '\ea1e'; } /* '' */
.mDoc-figma:before { content: '\ea1f'; } /* '' */
.mDoc-figma-1:before { content: '\ea20'; } /* '' */
.mDoc-flash:before { content: '\ea21'; } /* '' */
.mDoc-folder:before { content: '\ea22'; } /* '' */
.mDoc-folder-2:before { content: '\ea23'; } /* '' */
.mDoc-folder-add:before { content: '\ea24'; } /* '' */
.mDoc-folder-cloud:before { content: '\ea25'; } /* '' */
.mDoc-folder-cross:before { content: '\ea26'; } /* '' */
.mDoc-folder-favorite:before { content: '\ea27'; } /* '' */
.mDoc-folder-minus:before { content: '\ea28'; } /* '' */
.mDoc-folder-open:before { content: '\ea29'; } /* '' */
.mDoc-frame-1:before { content: '\ea2a'; } /* '' */
.mDoc-framer:before { content: '\ea2b'; } /* '' */
.mDoc-google-drive:before { content: '\ea2c'; } /* '' */
.mDoc-google-paly:before { content: '\ea2d'; } /* '' */
.mDoc-hedera-hashgraph-(hbar):before { content: '\ea2e'; } /* '' */
.mDoc-icon-1:before { content: '\ea2f'; } /* '' */
.mDoc-icon-2:before { content: '\ea30'; } /* '' */
.mDoc-illustrator:before { content: '\ea31'; } /* '' */
.mDoc-message:before { content: '\ea32'; } /* '' */
.mDoc-message-2:before { content: '\ea33'; } /* '' */
.mDoc-message-add:before { content: '\ea34'; } /* '' */
.mDoc-message-add-1:before { content: '\ea35'; } /* '' */
.mDoc-message-circle:before { content: '\ea36'; } /* '' */
.mDoc-message-edit:before { content: '\ea37'; } /* '' */
.mDoc-message-favorite:before { content: '\ea38'; } /* '' */
.mDoc-message-notif:before { content: '\ea39'; } /* '' */
.mDoc-message-remove:before { content: '\ea3a'; } /* '' */
.mDoc-messages:before { content: '\ea3b'; } /* '' */
.mDoc-messages-1:before { content: '\ea3c'; } /* '' */
.mDoc-messages-3:before { content: '\ea3d'; } /* '' */
.mDoc-message-search:before { content: '\ea3e'; } /* '' */
.mDoc-message-square:before { content: '\ea3f'; } /* '' */
.mDoc-message-text:before { content: '\ea40'; } /* '' */
.mDoc-message-text-1:before { content: '\ea41'; } /* '' */
.mDoc-message-tick:before { content: '\ea42'; } /* '' */
.mDoc-message-time:before { content: '\ea43'; } /* '' */
.mDoc-messenger:before { content: '\ea44'; } /* '' */
.mDoc-moon:before { content: '\ea45'; } /* '' */
.mDoc-paypal:before { content: '\ea46'; } /* '' */
.mDoc-people:before { content: '\ea47'; } /* '' */
.mDoc-photoshop:before { content: '\ea48'; } /* '' */
.mDoc-profile-2user:before { content: '\ea49'; } /* '' */
.mDoc-profile-add:before { content: '\ea4a'; } /* '' */
.mDoc-profile-circle:before { content: '\ea4b'; } /* '' */
.mDoc-profile-delete:before { content: '\ea4c'; } /* '' */
.mDoc-profile-remove:before { content: '\ea4d'; } /* '' */
.mDoc-profile-tick:before { content: '\ea4e'; } /* '' */
.mDoc-python:before { content: '\ea4f'; } /* '' */
.mDoc-sms:before { content: '\ea50'; } /* '' */
.mDoc-sms-edit:before { content: '\ea51'; } /* '' */
.mDoc-sms-notification:before { content: '\ea52'; } /* '' */
.mDoc-sms-search:before { content: '\ea53'; } /* '' */
.mDoc-sms-star:before { content: '\ea54'; } /* '' */
.mDoc-spotify:before { content: '\ea55'; } /* '' */
.mDoc-sun:before { content: '\ea56'; } /* '' */
.mDoc-sun-1:before { content: '\ea57'; } /* '' */
.mDoc-sun-fog:before { content: '\ea58'; } /* '' */
.mDoc-tag-user:before { content: '\ea59'; } /* '' */
.mDoc-trello:before { content: '\ea5a'; } /* '' */
.mDoc-twitch:before { content: '\ea5b'; } /* '' */
.mDoc-user:before { content: '\ea5c'; } /* '' */
.mDoc-user-add:before { content: '\ea5d'; } /* '' */
.mDoc-user-cirlce-add:before { content: '\ea5e'; } /* '' */
.mDoc-user-edit:before { content: '\ea5f'; } /* '' */
.mDoc-user-minus:before { content: '\ea60'; } /* '' */
.mDoc-user-octagon:before { content: '\ea61'; } /* '' */
.mDoc-user-remove:before { content: '\ea62'; } /* '' */
.mDoc-user-search:before { content: '\ea63'; } /* '' */
.mDoc-user-square:before { content: '\ea64'; } /* '' */
.mDoc-user-tag:before { content: '\ea65'; } /* '' */
.mDoc-user-tick:before { content: '\ea66'; } /* '' */
.mDoc-vuesax:before { content: '\ea67'; } /* '' */
.mDoc-whatsapp:before { content: '\ea68'; } /* '' */
.mDoc-wind:before { content: '\ea69'; } /* '' */
.mDoc-wind-2:before { content: '\ea6a'; } /* '' */
.mDoc-windows:before { content: '\ea6b'; } /* '' */
.mDoc-xd:before { content: '\ea6c'; } /* '' */
.mDoc-xiaomi:before { content: '\ea6d'; } /* '' */
.mDoc-youtube:before { content: '\ea6e'; } /* '' */
.mDoc-zoom:before { content: '\ea6f'; } /* '' */
.mDoc-aave-(aave):before { content: '\ea70'; } /* '' */
.mDoc-ankr-(ankr):before { content: '\ea71'; } /* '' */
.mDoc-apple:before { content: '\ea72'; } /* '' */
.mDoc-arrange-circle:before { content: '\ea73'; } /* '' */
.mDoc-arrange-circle-2:before { content: '\ea74'; } /* '' */
.mDoc-arrow-circle-down:before { content: '\ea75'; } /* '' */
.mDoc-arrow-circle-left:before { content: '\ea76'; } /* '' */
.mDoc-arrow-circle-right:before { content: '\ea77'; } /* '' */
.mDoc-arrow-circle-up:before { content: '\ea78'; } /* '' */
.mDoc-arrow-down-2:before { content: '\ea79'; } /* '' */
.mDoc-arrow-left-2:before { content: '\ea7a'; } /* '' */
.mDoc-arrow-left-3:before { content: '\ea7b'; } /* '' */
.mDoc-arrow-square-left:before { content: '\ea7c'; } /* '' */
.mDoc-arrow-square-up:before { content: '\ea7d'; } /* '' */
.mDoc-arrow-swap:before { content: '\ea7e'; } /* '' */
.mDoc-arrow-swap-horizontal:before { content: '\ea7f'; } /* '' */
.mDoc-arrow-up-2:before { content: '\ea80'; } /* '' */
.mDoc-arrow-up-3:before { content: '\ea81'; } /* '' */
.mDoc-augur-(rep):before { content: '\ea82'; } /* '' */
.mDoc-autonio-(niox):before { content: '\ea83'; } /* '' */
.mDoc-avalanche-(avax):before { content: '\ea84'; } /* '' */
.mDoc-binance-coin-(bnb):before { content: '\ea85'; } /* '' */
.mDoc-binance-usd-(busd):before { content: '\ea86'; } /* '' */
.mDoc-bitcoin-(btc):before { content: '\ea87'; } /* '' */
.mDoc-bootstrap:before { content: '\ea88'; } /* '' */
.mDoc-cardano-(ada):before { content: '\ea89'; } /* '' */
.mDoc-celo-(celo):before { content: '\ea8a'; } /* '' */
.mDoc-celsius-(cel)-:before { content: '\ea8b'; } /* '' */
.mDoc-chainlink-(link):before { content: '\ea8c'; } /* '' */
.mDoc-civic-(cvc):before { content: '\ea8d'; } /* '' */
.mDoc-convert:before { content: '\ea8e'; } /* '' */
.mDoc-dai-(dai):before { content: '\ea8f'; } /* '' */
.mDoc-dash-(dash):before { content: '\ea90'; } /* '' */
.mDoc-decred-(dcr):before { content: '\ea91'; } /* '' */
.mDoc-dent-(dent):before { content: '\ea92'; } /* '' */
.mDoc-dropbox:before { content: '\ea93'; } /* '' */
.mDoc-educare-(ekt):before { content: '\ea94'; } /* '' */
.mDoc-emercoin-(emc):before { content: '\ea95'; } /* '' */
.mDoc-enjin-coin-(enj):before { content: '\ea96'; } /* '' */
.mDoc-eos-(eos):before { content: '\ea97'; } /* '' */
.mDoc-ethereum-(eth):before { content: '\ea98'; } /* '' */
.mDoc-ethereum-classic-(etc):before { content: '\ea99'; } /* '' */
.mDoc-export-2:before { content: '\ea9a'; } /* '' */
.mDoc-export-3:before { content: '\ea9b'; } /* '' */
.mDoc-facebook:before { content: '\ea9c'; } /* '' */
.mDoc-frame:before { content: '\ea9d'; } /* '' */
.mDoc-ftx-token-(ftt):before { content: '\ea9e'; } /* '' */
.mDoc-google:before { content: '\ea9f'; } /* '' */
.mDoc-harmony-(one):before { content: '\eaa0'; } /* '' */
.mDoc-hex-(hex):before { content: '\eaa1'; } /* '' */
.mDoc-html-3:before { content: '\eaa2'; } /* '' */
.mDoc-html-5:before { content: '\eaa3'; } /* '' */
.mDoc-huobi-token-(ht):before { content: '\eaa4'; } /* '' */
.mDoc-icon-(icx):before { content: '\eaa5'; } /* '' */
.mDoc-icon:before { content: '\eaa6'; } /* '' */
.mDoc-import-2:before { content: '\eaa7'; } /* '' */
.mDoc-iost-(iost):before { content: '\eaa8'; } /* '' */
.mDoc-java-script:before { content: '\eaa9'; } /* '' */
.mDoc-js:before { content: '\eaaa'; } /* '' */
.mDoc-kyber-network-(knc):before { content: '\eaab'; } /* '' */
.mDoc-litecoin(ltc):before { content: '\eaac'; } /* '' */
.mDoc-maker-(mkr):before { content: '\eaad'; } /* '' */
.mDoc-monero-(xmr):before { content: '\eaae'; } /* '' */
.mDoc-nebulas-(nas):before { content: '\eaaf'; } /* '' */
.mDoc-nem-(xem):before { content: '\eab0'; } /* '' */
.mDoc-nexo-(nexo):before { content: '\eab1'; } /* '' */
.mDoc-ocean-protocol-(ocean):before { content: '\eab2'; } /* '' */
.mDoc-okb-(okb):before { content: '\eab3'; } /* '' */
.mDoc-ontology-(ont):before { content: '\eab4'; } /* '' */
.mDoc-polkadot-(dot):before { content: '\eab5'; } /* '' */
.mDoc-polygon-(matic):before { content: '\eab6'; } /* '' */
.mDoc-polyswarm-(nct):before { content: '\eab7'; } /* '' */
.mDoc-quant-(qnt):before { content: '\eab8'; } /* '' */
.mDoc-redo:before { content: '\eab9'; } /* '' */
.mDoc-refresh-2:before { content: '\eaba'; } /* '' */
.mDoc-refresh-circle:before { content: '\eabb'; } /* '' */
.mDoc-repeat:before { content: '\eabc'; } /* '' */
.mDoc-repeat-circle:before { content: '\eabd'; } /* '' */
.mDoc-rotate-left:before { content: '\eabe'; } /* '' */
.mDoc-rotate-right:before { content: '\eabf'; } /* '' */
.mDoc-siacoin-(sc):before { content: '\eac0'; } /* '' */
.mDoc-slack:before { content: '\eac1'; } /* '' */
.mDoc-snapchat:before { content: '\eac2'; } /* '' */
.mDoc-solana-(sol):before { content: '\eac3'; } /* '' */
.mDoc-stacks-(stx):before { content: '\eac4'; } /* '' */
.mDoc-stellar-(xlm):before { content: '\eac5'; } /* '' */
.mDoc-tenx-(pay):before { content: '\eac6'; } /* '' */
.mDoc-tether-(usdt):before { content: '\eac7'; } /* '' */
.mDoc-the-graph-(grt):before { content: '\eac8'; } /* '' */
.mDoc-theta-(theta):before { content: '\eac9'; } /* '' */
.mDoc-thorchain-(rune):before { content: '\eaca'; } /* '' */
.mDoc-triangle:before { content: '\eacb'; } /* '' */
.mDoc-trontron-(trx):before { content: '\eacc'; } /* '' */
.mDoc-ui8:before { content: '\eacd'; } /* '' */
.mDoc-undo:before { content: '\eace'; } /* '' */
.mDoc-usd-coin-(usdc):before { content: '\eacf'; } /* '' */
.mDoc-velas-(vlx):before { content: '\ead0'; } /* '' */
.mDoc-vibe-(vibe):before { content: '\ead1'; } /* '' */
.mDoc-wanchain-(wan):before { content: '\ead2'; } /* '' */
.mDoc-wanchain-(wan)-1:before { content: '\ead3'; } /* '' */
.mDoc-wing-(wing):before { content: '\ead4'; } /* '' */
.mDoc-xrp-(xrp):before { content: '\ead5'; } /* '' */
.mDoc-zel-(zel):before { content: '\ead6'; } /* '' */
.mDoc-arrange-square:before { content: '\ead7'; } /* '' */
.mDoc-arrange-square-2:before { content: '\ead8'; } /* '' */
.mDoc-arrow-2:before { content: '\ead9'; } /* '' */
.mDoc-arrow-3:before { content: '\eada'; } /* '' */
.mDoc-arrow-bottom:before { content: '\eadb'; } /* '' */
.mDoc-arrow-down:before { content: '\eadc'; } /* '' */
.mDoc-arrow-down-1:before { content: '\eadd'; } /* '' */
.mDoc-arrow-left:before { content: '\eade'; } /* '' */
.mDoc-arrow-left-1:before { content: '\eadf'; } /* '' */
.mDoc-arrow-right:before { content: '\eae0'; } /* '' */
.mDoc-arrow-right-1:before { content: '\eae1'; } /* '' */
.mDoc-arrow-right-2:before { content: '\eae2'; } /* '' */
.mDoc-arrow-right-3:before { content: '\eae3'; } /* '' */
.mDoc-arrow-right-4:before { content: '\eae4'; } /* '' */
.mDoc-arrow-square-down:before { content: '\eae5'; } /* '' */
.mDoc-arrow-up:before { content: '\eae6'; } /* '' */
.mDoc-arrow-up-1:before { content: '\eae7'; } /* '' */
.mDoc-audio-square:before { content: '\eae8'; } /* '' */
.mDoc-back-square:before { content: '\eae9'; } /* '' */
.mDoc-backward:before { content: '\eaea'; } /* '' */
.mDoc-backward-5-seconds:before { content: '\eaeb'; } /* '' */
.mDoc-backward-10-seconds:before { content: '\eaec'; } /* '' */
.mDoc-backward-15-seconds:before { content: '\eaed'; } /* '' */
.mDoc-devices:before { content: '\eaee'; } /* '' */
.mDoc-export:before { content: '\eaef'; } /* '' */
.mDoc-export-1:before { content: '\eaf0'; } /* '' */
.mDoc-forward:before { content: '\eaf1'; } /* '' */
.mDoc-forward-5-seconds:before { content: '\eaf2'; } /* '' */
.mDoc-forward-10-seconds:before { content: '\eaf3'; } /* '' */
.mDoc-forward-15-seconds:before { content: '\eaf4'; } /* '' */
.mDoc-forward-square:before { content: '\eaf5'; } /* '' */
.mDoc-gallery-favorite:before { content: '\eaf6'; } /* '' */
.mDoc-gallery-import:before { content: '\eaf7'; } /* '' */
.mDoc-gallery-slash:before { content: '\eaf8'; } /* '' */
.mDoc-gallery-tick:before { content: '\eaf9'; } /* '' */
.mDoc-import:before { content: '\eafa'; } /* '' */
.mDoc-import-1:before { content: '\eafb'; } /* '' */
.mDoc-login:before { content: '\eafc'; } /* '' */
.mDoc-login-1:before { content: '\eafd'; } /* '' */
.mDoc-logout:before { content: '\eafe'; } /* '' */
.mDoc-logout-1:before { content: '\eaff'; } /* '' */
.mDoc-maximize-circle:before { content: '\eb00'; } /* '' */
.mDoc-microphone-slash:before { content: '\eb01'; } /* '' */
.mDoc-microphone-slash-1:before { content: '\eb02'; } /* '' */
.mDoc-mini-music-sqaure:before { content: '\eb03'; } /* '' */
.mDoc-music-circle:before { content: '\eb04'; } /* '' */
.mDoc-music-dashboard:before { content: '\eb05'; } /* '' */
.mDoc-music-filter:before { content: '\eb06'; } /* '' */
.mDoc-music-library-2:before { content: '\eb07'; } /* '' */
.mDoc-musicnote:before { content: '\eb08'; } /* '' */
.mDoc-music-playlist:before { content: '\eb09'; } /* '' */
.mDoc-music-square:before { content: '\eb0a'; } /* '' */
.mDoc-music-square-add:before { content: '\eb0b'; } /* '' */
.mDoc-music-square-search:before { content: '\eb0c'; } /* '' */
.mDoc-next:before { content: '\eb0d'; } /* '' */
.mDoc-note-square:before { content: '\eb0e'; } /* '' */
.mDoc-pause:before { content: '\eb0f'; } /* '' */
.mDoc-pause-circle:before { content: '\eb10'; } /* '' */
.mDoc-play-add:before { content: '\eb11'; } /* '' */
.mDoc-play-circle:before { content: '\eb12'; } /* '' */
.mDoc-play-cricle:before { content: '\eb13'; } /* '' */
.mDoc-play-remove:before { content: '\eb14'; } /* '' */
.mDoc-previous:before { content: '\eb15'; } /* '' */
.mDoc-radio:before { content: '\eb16'; } /* '' */
.mDoc-received:before { content: '\eb17'; } /* '' */
.mDoc-receive-square:before { content: '\eb18'; } /* '' */
.mDoc-receive-square-2:before { content: '\eb19'; } /* '' */
.mDoc-record-circle:before { content: '\eb1a'; } /* '' */
.mDoc-refresh:before { content: '\eb1b'; } /* '' */
.mDoc-refresh-left-square:before { content: '\eb1c'; } /* '' */
.mDoc-refresh-right-square:before { content: '\eb1d'; } /* '' */
.mDoc-refresh-square-2:before { content: '\eb1e'; } /* '' */
.mDoc-repeate-music:before { content: '\eb1f'; } /* '' */
.mDoc-repeate-one:before { content: '\eb20'; } /* '' */
.mDoc-scissor:before { content: '\eb21'; } /* '' */
.mDoc-send:before { content: '\eb22'; } /* '' */
.mDoc-send-sqaure-2:before { content: '\eb23'; } /* '' */
.mDoc-send-square:before { content: '\eb24'; } /* '' */
.mDoc-shuffle:before { content: '\eb25'; } /* '' */
.mDoc-stop-circle:before { content: '\eb26'; } /* '' */
.mDoc-subtitle:before { content: '\eb27'; } /* '' */
.mDoc-video:before { content: '\eb28'; } /* '' */
.mDoc-video-add:before { content: '\eb29'; } /* '' */
.mDoc-video-circle:before { content: '\eb2a'; } /* '' */
.mDoc-video-horizontal:before { content: '\eb2b'; } /* '' */
.mDoc-video-octagon:before { content: '\eb2c'; } /* '' */
.mDoc-video-play:before { content: '\eb2d'; } /* '' */
.mDoc-video-remove:before { content: '\eb2e'; } /* '' */
.mDoc-video-slash:before { content: '\eb2f'; } /* '' */
.mDoc-video-square:before { content: '\eb30'; } /* '' */
.mDoc-video-tick:before { content: '\eb31'; } /* '' */
.mDoc-video-vertical:before { content: '\eb32'; } /* '' */
.mDoc-voice-cricle:before { content: '\eb33'; } /* '' */
.mDoc-voice-square:before { content: '\eb34'; } /* '' */
.mDoc-volume-cross:before { content: '\eb35'; } /* '' */
.mDoc-volume-high:before { content: '\eb36'; } /* '' */
.mDoc-volume-low-1:before { content: '\eb37'; } /* '' */
.mDoc-volume-mute:before { content: '\eb38'; } /* '' */
.mDoc-volume-slash:before { content: '\eb39'; } /* '' */
.mDoc-volume-up:before { content: '\eb3a'; } /* '' */
.mDoc-attach-circle:before { content: '\eb3b'; } /* '' */
.mDoc-attach-square:before { content: '\eb3c'; } /* '' */
.mDoc-camera:before { content: '\eb3d'; } /* '' */
.mDoc-camera-slash:before { content: '\eb3e'; } /* '' */
.mDoc-card-add:before { content: '\eb3f'; } /* '' */
.mDoc-card-edit:before { content: '\eb40'; } /* '' */
.mDoc-card-receive:before { content: '\eb41'; } /* '' */
.mDoc-card-remove-1:before { content: '\eb42'; } /* '' */
.mDoc-card-slash:before { content: '\eb43'; } /* '' */
.mDoc-card-tick-1:before { content: '\eb44'; } /* '' */
.mDoc-coin-1:before { content: '\eb45'; } /* '' */
.mDoc-convert-card:before { content: '\eb46'; } /* '' */
.mDoc-discount-circle:before { content: '\eb47'; } /* '' */
.mDoc-discount-shape:before { content: '\eb48'; } /* '' */
.mDoc-document:before { content: '\eb49'; } /* '' */
.mDoc-dollar-circle:before { content: '\eb4a'; } /* '' */
.mDoc-empty-wallet-change:before { content: '\eb4b'; } /* '' */
.mDoc-empty-wallet-tick:before { content: '\eb4c'; } /* '' */
.mDoc-empty-wallet-time:before { content: '\eb4d'; } /* '' */
.mDoc-eraser:before { content: '\eb4e'; } /* '' */
.mDoc-firstline:before { content: '\eb4f'; } /* '' */
.mDoc-gallery:before { content: '\eb50'; } /* '' */
.mDoc-gallery-add:before { content: '\eb51'; } /* '' */
.mDoc-gallery-edit:before { content: '\eb52'; } /* '' */
.mDoc-gallery-export:before { content: '\eb53'; } /* '' */
.mDoc-gallery-remove:before { content: '\eb54'; } /* '' */
.mDoc-image:before { content: '\eb55'; } /* '' */
.mDoc-language-circle:before { content: '\eb56'; } /* '' */
.mDoc-language-square:before { content: '\eb57'; } /* '' */
.mDoc-link:before { content: '\eb58'; } /* '' */
.mDoc-link-1:before { content: '\eb59'; } /* '' */
.mDoc-link-2:before { content: '\eb5a'; } /* '' */
.mDoc-link-21:before { content: '\eb5b'; } /* '' */
.mDoc-link-circle:before { content: '\eb5c'; } /* '' */
.mDoc-link-square:before { content: '\eb5d'; } /* '' */
.mDoc-math:before { content: '\eb5e'; } /* '' */
.mDoc-maximize:before { content: '\eb5f'; } /* '' */
.mDoc-microphone:before { content: '\eb60'; } /* '' */
.mDoc-microphone-2:before { content: '\eb61'; } /* '' */
.mDoc-money:before { content: '\eb62'; } /* '' */
.mDoc-money-2:before { content: '\eb63'; } /* '' */
.mDoc-money-add:before { content: '\eb64'; } /* '' */
.mDoc-money-change:before { content: '\eb65'; } /* '' */
.mDoc-money-forbidden:before { content: '\eb66'; } /* '' */
.mDoc-money-recive:before { content: '\eb67'; } /* '' */
.mDoc-money-remove:before { content: '\eb68'; } /* '' */
.mDoc-money-send:before { content: '\eb69'; } /* '' */
.mDoc-money-tick:before { content: '\eb6a'; } /* '' */
.mDoc-money-time:before { content: '\eb6b'; } /* '' */
.mDoc-music:before { content: '\eb6c'; } /* '' */
.mDoc-music-square-remove:before { content: '\eb6d'; } /* '' */
.mDoc-paperclip:before { content: '\eb6e'; } /* '' */
.mDoc-paperclip-2:before { content: '\eb6f'; } /* '' */
.mDoc-percentage-square:before { content: '\eb70'; } /* '' */
.mDoc-pharagraphspacing:before { content: '\eb71'; } /* '' */
.mDoc-play:before { content: '\eb72'; } /* '' */
.mDoc-quote-down:before { content: '\eb73'; } /* '' */
.mDoc-quote-down-circle:before { content: '\eb74'; } /* '' */
.mDoc-quote-down-square:before { content: '\eb75'; } /* '' */
.mDoc-quote-up:before { content: '\eb76'; } /* '' */
.mDoc-quote-up-circle:before { content: '\eb77'; } /* '' */
.mDoc-quote-up-square:before { content: '\eb78'; } /* '' */
.mDoc-receipt-add:before { content: '\eb79'; } /* '' */
.mDoc-receipt-disscount:before { content: '\eb7a'; } /* '' */
.mDoc-receipt-text:before { content: '\eb7b'; } /* '' */
.mDoc-record:before { content: '\eb7c'; } /* '' */
.mDoc-screenmirroring:before { content: '\eb7d'; } /* '' */
.mDoc-smallcaps:before { content: '\eb7e'; } /* '' */
.mDoc-stop:before { content: '\eb7f'; } /* '' */
.mDoc-strongbox:before { content: '\eb80'; } /* '' */
.mDoc-strongbox-2:before { content: '\eb81'; } /* '' */
.mDoc-tag:before { content: '\eb82'; } /* '' */
.mDoc-tag-2:before { content: '\eb83'; } /* '' */
.mDoc-text:before { content: '\eb84'; } /* '' */
.mDoc-textalign-center:before { content: '\eb85'; } /* '' */
.mDoc-textalign-justifycenter:before { content: '\eb86'; } /* '' */
.mDoc-textalign-justifyleft:before { content: '\eb87'; } /* '' */
.mDoc-textalign-justifyright:before { content: '\eb88'; } /* '' */
.mDoc-textalign-left:before { content: '\eb89'; } /* '' */
.mDoc-textalign-right:before { content: '\eb8a'; } /* '' */
.mDoc-text-block:before { content: '\eb8b'; } /* '' */
.mDoc-text-bold:before { content: '\eb8c'; } /* '' */
.mDoc-text-italic:before { content: '\eb8d'; } /* '' */
.mDoc-text-underline:before { content: '\eb8e'; } /* '' */
.mDoc-ticket:before { content: '\eb8f'; } /* '' */
.mDoc-ticket-expired:before { content: '\eb90'; } /* '' */
.mDoc-ticket-star:before { content: '\eb91'; } /* '' */
.mDoc-transaction-minus:before { content: '\eb92'; } /* '' */
.mDoc-translate:before { content: '\eb93'; } /* '' */
.mDoc-video-time:before { content: '\eb94'; } /* '' */
.mDoc-volume-low:before { content: '\eb95'; } /* '' */
.mDoc-wallet-1:before { content: '\eb96'; } /* '' */
.mDoc-wallet-2:before { content: '\eb97'; } /* '' */
.mDoc-wallet-3:before { content: '\eb98'; } /* '' */
.mDoc-wallet-add-1:before { content: '\eb99'; } /* '' */
.mDoc-wallet-minus:before { content: '\eb9a'; } /* '' */
.mDoc-wallet-money:before { content: '\eb9b'; } /* '' */
.mDoc-wallet-search:before { content: '\eb9c'; } /* '' */
.mDoc-archive:before { content: '\eb9d'; } /* '' */
.mDoc-card:before { content: '\eb9e'; } /* '' */
.mDoc-card-pos:before { content: '\eb9f'; } /* '' */
.mDoc-card-remove:before { content: '\eba0'; } /* '' */
.mDoc-cards:before { content: '\eba1'; } /* '' */
.mDoc-card-send:before { content: '\eba2'; } /* '' */
.mDoc-card-tick:before { content: '\eba3'; } /* '' */
.mDoc-chart-square:before { content: '\eba4'; } /* '' */
.mDoc-coin:before { content: '\eba5'; } /* '' */
.mDoc-dollar-square:before { content: '\eba6'; } /* '' */
.mDoc-empty-wallet:before { content: '\eba7'; } /* '' */
.mDoc-empty-wallet-add:before { content: '\eba8'; } /* '' */
.mDoc-empty-wallet-remove:before { content: '\eba9'; } /* '' */
.mDoc-money-3:before { content: '\ebaa'; } /* '' */
.mDoc-money-4:before { content: '\ebab'; } /* '' */
.mDoc-moneys:before { content: '\ebac'; } /* '' */
.mDoc-percentage-circle:before { content: '\ebad'; } /* '' */
.mDoc-receipt:before { content: '\ebae'; } /* '' */
.mDoc-receipt-1:before { content: '\ebaf'; } /* '' */
.mDoc-receipt-2:before { content: '\ebb0'; } /* '' */
.mDoc-receipt-2-1:before { content: '\ebb1'; } /* '' */
.mDoc-receipt-discount:before { content: '\ebb2'; } /* '' */
.mDoc-receipt-edit:before { content: '\ebb3'; } /* '' */
.mDoc-receipt-item:before { content: '\ebb4'; } /* '' */
.mDoc-receipt-minus:before { content: '\ebb5'; } /* '' */
.mDoc-receipt-search:before { content: '\ebb6'; } /* '' */
.mDoc-security-card:before { content: '\ebb7'; } /* '' */
.mDoc-ticket-2:before { content: '\ebb8'; } /* '' */
.mDoc-ticket-discount:before { content: '\ebb9'; } /* '' */
.mDoc-wallet:before { content: '\ebba'; } /* '' */
.mDoc-wallet-add:before { content: '\ebbb'; } /* '' */
.mDoc-wallet-check:before { content: '\ebbc'; } /* '' */
.mDoc-wallet-remove:before { content: '\ebbd'; } /* '' */
.mDoc-3dcube:before { content: '\ebbe'; } /* '' */
.mDoc-3full:before { content: '\ebbf'; } /* '' */
.mDoc-aquarius:before { content: '\ebc0'; } /* '' */
.mDoc-autobrightness:before { content: '\ebc1'; } /* '' */
.mDoc-bank:before { content: '\ebc2'; } /* '' */
.mDoc-battery-empty-1:before { content: '\ebc3'; } /* '' */
.mDoc-box-2:before { content: '\ebc4'; } /* '' */
.mDoc-building:before { content: '\ebc5'; } /* '' */
.mDoc-building-3:before { content: '\ebc6'; } /* '' */
.mDoc-building-4:before { content: '\ebc7'; } /* '' */
.mDoc-buildings:before { content: '\ebc8'; } /* '' */
.mDoc-buildings-2:before { content: '\ebc9'; } /* '' */
.mDoc-buliding:before { content: '\ebca'; } /* '' */
.mDoc-chart-3:before { content: '\ebcb'; } /* '' */
.mDoc-chrome:before { content: '\ebcc'; } /* '' */
.mDoc-close-square:before { content: '\ebcd'; } /* '' */
.mDoc-coffee:before { content: '\ebce'; } /* '' */
.mDoc-computing:before { content: '\ebcf'; } /* '' */
.mDoc-courthouse:before { content: '\ebd0'; } /* '' */
.mDoc-discover-1:before { content: '\ebd1'; } /* '' */
.mDoc-filter:before { content: '\ebd2'; } /* '' */
.mDoc-filter-square:before { content: '\ebd3'; } /* '' */
.mDoc-filter-tick:before { content: '\ebd4'; } /* '' */
.mDoc-flag-2:before { content: '\ebd5'; } /* '' */
.mDoc-gemini:before { content: '\ebd6'; } /* '' */
.mDoc-gemini-2:before { content: '\ebd7'; } /* '' */
.mDoc-glass-1:before { content: '\ebd8'; } /* '' */
.mDoc-happyemoji:before { content: '\ebd9'; } /* '' */
.mDoc-home-1:before { content: '\ebda'; } /* '' */
.mDoc-home-wifi:before { content: '\ebdb'; } /* '' */
.mDoc-hospital:before { content: '\ebdc'; } /* '' */
.mDoc-house:before { content: '\ebdd'; } /* '' */
.mDoc-house-2:before { content: '\ebde'; } /* '' */
.mDoc-information:before { content: '\ebdf'; } /* '' */
.mDoc-instagram:before { content: '\ebe0'; } /* '' */
.mDoc-judge:before { content: '\ebe1'; } /* '' */
.mDoc-lamp-1:before { content: '\ebe2'; } /* '' */
.mDoc-level:before { content: '\ebe3'; } /* '' */
.mDoc-man:before { content: '\ebe4'; } /* '' */
.mDoc-toilet:before { content: '\ebe5'; } /* '' */
.mDoc-toiletpaper:before { content: '\ebe6'; } /* '' */
.mDoc-tooth:before { content: '\ebe7'; } /* '' */
.mDoc-virus:before { content: '\ebe8'; } /* '' */
.mDoc-wheelchair:before { content: '\ebe9'; } /* '' */
.mDoc-wheelchairmotion:before { content: '\ebea'; } /* '' */
.mDoc-bandaids:before { content: '\ebeb'; } /* '' */
.mDoc-barbell:before { content: '\ebec'; } /* '' */
.mDoc-brain:before { content: '\ebed'; } /* '' */
.mDoc-bed:before { content: '\ebee'; } /* '' */
.mDoc-chatcenteredtext:before { content: '\ebef'; } /* '' */
.mDoc-dna:before { content: '\ebf0'; } /* '' */
.mDoc-facemask:before { content: '\ebf1'; } /* '' */
.mDoc-firstaid:before { content: '\ebf2'; } /* '' */
.mDoc-firstaidkit:before { content: '\ebf3'; } /* '' */
.mDoc-flask:before { content: '\ebf4'; } /* '' */
.mDoc-foldersimplelock:before { content: '\ebf5'; } /* '' */
.mDoc-foldersimplestar:before { content: '\ebf6'; } /* '' */
.mDoc-foldersimpleuser:before { content: '\ebf7'; } /* '' */
.mDoc-folderstar:before { content: '\ebf8'; } /* '' */
.mDoc-folderuser:before { content: '\ebf9'; } /* '' */
.mDoc-genderfemale:before { content: '\ebfa'; } /* '' */
.mDoc-gendermale:before { content: '\ebfb'; } /* '' */
.mDoc-handheart:before { content: '\ebfc'; } /* '' */
.mDoc-handsoap:before { content: '\ebfd'; } /* '' */
.mDoc-heartbeat:before { content: '\ebfe'; } /* '' */
.mDoc-lifebuoy:before { content: '\ebff'; } /* '' */
.mDoc-person:before { content: '\ec00'; } /* '' */
.mDoc-personarmsspread:before { content: '\ec01'; } /* '' */
.mDoc-personsimplebike:before { content: '\ec02'; } /* '' */
.mDoc-personsimplerun:before { content: '\ec03'; } /* '' */
.mDoc-personsimplethrow:before { content: '\ec04'; } /* '' */
.mDoc-personsimplewalk:before { content: '\ec05'; } /* '' */
.mDoc-pill:before { content: '\ec06'; } /* '' */
.mDoc-prescription:before { content: '\ec07'; } /* '' */
.mDoc-pulse:before { content: '\ec08'; } /* '' */
.mDoc-stethoscope:before { content: '\ec09'; } /* '' */
.mDoc-syringe:before { content: '\ec0a'; } /* '' */
.mDoc-testtube:before { content: '\ec0b'; } /* '' */
